import styled from "styled-components";

export const QuickAddWrapper = styled.div<{
  disabled?: boolean;
  isWeightVariable?: boolean;
}>`
  display: flex;
  flex-wrap: wrap;
  user-select: none;
  gap: ${({ theme }) => theme.space.regular};

  filter: saturate(${({ disabled }) => (disabled ? 0 : 1)});
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

  & div button {
    background: ${({ theme, disabled }) => disabled && theme.colors.grayDarker};
  }
`;

export const ExtraButton = styled.button`
  flex: auto;
  height: 32px;
  display: flex;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  background-color: white;
  cursor: pointer;
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSizes.tsm};
  color: ${({ theme }) => theme.colors.grayDarkest};
  font-family: ${({ theme }) => theme.font.family.ifood.body};
  border: solid 1px ${({ theme }) => theme.colors.primary};

  &:hover {
    filter: brightness(0.97);
  }
`;
