import { Icon } from "@ifood/pomodoro-components";
import styled from "styled-components";
import { Text } from "../../../Text";

type QuickAddProps = {
  isEmpty?: boolean;
};

export const QuickAddContainer = styled.div<QuickAddProps>`
  margin: 0;
  width: 100%;
  height: 48px;
  overflow: hidden;
  background-color: white;

  display: flex;
  align-items: center;
  justify-content: space-between;

  border-radius: 8px;
  border: solid 1px
    ${({ isEmpty, theme }) => (isEmpty ? "transparent" : theme.colors.gray)};
`;

export const Label = styled.button<QuickAddProps>`
  color: white;
  width: 100%;
  height: 100%;
  text-align: center;
  font-weight: 600;
  border: none;
  cursor: pointer;
  font-family: ${({ theme }) => theme.font.family.ifood.body};
  font-size: ${({ theme }) => theme.fontSizes.s};
  background: ${({ theme }) => theme.colors.primary};
  display: ${({ isEmpty }) => (isEmpty ? "block" : "none")};

  &:hover {
    filter: brightness(0.9);
  }
`;

export const Value = styled(Text)<QuickAddProps>`
  height: 30px;
  min-width: 25px;
  line-height: 30px;
  text-align: center;
  font-weight: 500;
  margin: 3px 0;
  margin: 0 -6px;
  color: ${({ theme }) => theme.colors.black};
  display: ${({ isEmpty }) => (isEmpty ? "none" : "block")};
  font-size: 16px;
`;

export const PlusIcon = styled(Icon)<QuickAddProps>`
  cursor: pointer;
  padding: 0 9px;
  color: ${({ theme }) => theme.colors.primary};
  display: ${({ isEmpty }) => (isEmpty ? "none" : "block")};

  &:hover {
    scale: 1.2;
    transition: all 100ms;
  }

  svg path {
    stroke-width: 2px;
    stroke: ${({ theme }) => theme.colors.primary};
  }
`;

export const MinusIcon = styled(PlusIcon)<QuickAddProps>`
  display: ${({ isEmpty }) => (isEmpty ? "none" : "block")};
`;
