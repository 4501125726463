import { Theme } from 'styled-system';

export const neutralTheme: Theme = {
  fonts: {
    primary: "iFood RC Textos",
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  font: {
    family: {
      ifood: {
        headings: "iFood RC Títulos",
        body: "iFood RC Textos",
      },
    },
  },
  colors: {
    heading: "#1A1A1A",
    primary: "#1A1A1A",
    primaryDark: "#9e9e9e",
    primaryLight: "#3F3E3E",
    primaryLightest: "#F5F5F5",
    error: "#FF4E4E",
  },
};
